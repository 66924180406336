import React from "react"
import { Link } from "gatsby"
import Hover from "../components/hover"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAt, faFile } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'

import styled from 'styled-components'

import resume from "../../static/resume.pdf"

const Container = styled.div`
    font-size: 52px;
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-top: 30px;
`

const Contact = () => (
    <Container>
        <Hover hoverText="download resume">
            <a href={resume}><FontAwesomeIcon icon={faFile} /></a>
        </Hover>
        <Hover hoverText="linkedin profile">
            <a href="https://www.linkedin.com/in/calvin-atlan-857a73115/" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faLinkedin} /></a>
        </Hover>
        <Hover hoverText="email me">
            <a href="mailto:calvin@calvinatlan.com"><FontAwesomeIcon icon={faAt} /></a>
        </Hover>
    </Container>
)

export default Contact
