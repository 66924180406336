import React from "react"

import Contact from '../components/contact'
import '../components/layout.css'

const IndexPage = () => (
    <div>
        <header>Calvin Atlan</header>
        <div className="main">
            <h1>Under Development</h1>
            <h3>Come back soon</h3>
            <Contact />
        </div>
    </div>
)

export default IndexPage
