import React from "react"
import styled from 'styled-components'

const HoverText = styled.span`
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    font-size: 24px;
`

const getVisibleStyle = (isVisible) => {
    return {
        visibility: isVisible ? "visible" : "hidden",
        opacity: isVisible ? "1" : "0",
        transition: "visibility 0.5s, opacity 0.5s linear"
    }
}

class Hover extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hover: false
        }
    }
    setHover(state) {
        this.setState({
            hover: state
        })
    }
    render() {
        return (
            <div style={{position: 'relative'}} onMouseEnter={this.setHover.bind(this,true)} onMouseLeave={this.setHover.bind(this,false)}>
                <div>
                {this.props.children}
                </div>
                <HoverText style={getVisibleStyle(this.state.hover)}>{this.props.hoverText}</HoverText>
            </div>
        )
    }
}

export default Hover
